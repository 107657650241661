<template>
  <a-table
    :columns="columns"
    :dataSource="list"
    :pagination="paginate"
    :row-key="(record, index) => record.id + index"
    @change="handleTableChange"
  >
    <span
      slot="id"
      slot-scope="text"
    >
      {{ text }}
    </span>
    <span slot="name" slot-scope="record"> {{ record.name || record.value }}</span>
    <span slot="comment" slot-scope="record">
      <template v-if="record.comment && record.comment.length > 50">
        {{ `${record.comment.slice(0, 50)}...` }}
      </template>
      <template v-else>
        {{ record.comment }}
      </template>
    </span>
    <span slot="status" slot-scope="record"> {{ usability[record.rating] }}</span>
    <span slot="date" slot-scope="record"> {{ record.created_at | getFormattedDate }}</span>
    <div class="h-flex" slot="action" slot-scope="record">
      <a
        href="javascript: void(0);"
        class="btn btn-sm btn-light mr-2"
        @click.prevent="$router.push({ path: `/functional/usability/${record.id}` })"
      >
        <small>
          <i class="fe fe-more-horizontal mr-2" />
        </small>
        Просмотр
      </a>

      <a-popconfirm
        title="Вы уверенны что хотите удалить?"
        ok-text="Да"
        cancel-text="Нет"
        @confirm="remove(record.id)"
      >
        <a href="javascript: void(0);" class="btn btn-sm btn-light">
          <small>
            <i class="fe fe-trash mr-2 pb-2" />
          </small>
          Удалить
        </a>
      </a-popconfirm>
    </div>
  </a-table>
</template>

<script>

import usability from '@/entities/usability'

export default {
  name: 'UsabilityTable',

  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    paginate: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      usability,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Комментарий',
          scopedSlots: { customRender: 'comment' },
        },
        {
          title: 'Оценка',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: 'Дата',
          sorter: true,
          dataIndex: 'created_at',
          scopedSlots: { customRender: 'date' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },

  methods: {
    handleTableChange(pagination, filters, sorter) {
      this.$emit('handleTableChange', pagination, filters, sorter)
    },

    async remove(id) {
      try {
        await this.$services.delete(`admin/statistic_rating/${id}`)
        this.$emit('getList')
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
