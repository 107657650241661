<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Функционал: Отзывы фильтра</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Отзывы фильтра</h5>
        </div>
      </div>
      <div class="card-body">
        <div class="users_statistics">
          <h4 class="mb-3">Статистика отзывов</h4>
          <div class="users_statistics--item" v-for="stat in usabilityStatistics" :key="stat.id">
            <div @click="date = stat.id">{{ stat.name }}</div>
            <div>{{ getCount(stat.alias) }}</div>
          </div>
        </div>

        <usability-table :list="list" :paginate="pagination" @handleTableChange="handleTableChange" @getList="getList" />
      </div>
    </div>
  </div>
</template>

<script>
import UsabilityTable from '@/components/functional/usability/UsabilityTable'
import usabilityStatistics from '@/entities/statistics'

export default {
  name: 'index',

  components: {
    UsabilityTable,
  },

  data() {
    return {
      usabilityStatistics,
      date: null,
      statistics: {},
      list: [],
      pagination: {
        current: 1,
        total: 1,
        pageSize: 10,
      },
    }
  },

  created() {
    this.getList()
  },

  watch: {
    date() {
      this.getList()
    },
  },

  computed: {
    queryParams() {
      return {
        page: this.pagination.current,
        date: this.date ? this.date : null,
        sort: this.sort ? this.sort : null,
      }
    },
  },

  methods: {
    async getList() {
      const params = this.queryParams

      const rating = (await this.$services.get('admin/statistic_rating', { params })).data.data.rating

      // eslint-disable-next-line camelcase
      const { today, week, month, three_month } = rating

      this.statistics = {
        today,
        week,
        month,
        three_month,
      }

      this.list = rating.data
      this.pagination = {
        current: rating.current_page,
        total: rating.total,
        pageSize: rating.per_page,
      }
    },

    handleTableChange(pagination, _, sorter) {
      this.pagination.current = pagination.current
      this.sort = sorter.order
        ? `${sorter.field}|${sorter.order.replace('end', '')}`
        : null

      this.getList()
    },

    getCount(alias) {
      return this.statistics[alias] || 0
    },
  },
}
</script>

<style lang="scss" scoped>
.users_statistics {
  max-width: 313px;
  padding: 16px;
  margin-bottom: 24px;
  border: 1px solid rgba(0, 0, 0, .3);
  border-radius: 5px;
  &--item {
    display: flex;
    justify-content: space-between;
    color: rgba(0,0,0,.87);
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 4px;
    div:first-child {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
